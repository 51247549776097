.wrapper {
  text-align: center;
}
.title {
  font-size: 28px;
  line-height: 42px;
  margin-top: 30px;
  margin-bottom: 0;
}
.innerContainer {
  border: 1px solid rgba(6, 22, 69, 0.1);
  border-radius: 8px;
  padding: 24px;
  margin: 32px 20px 32px 20px;
  text-align: start;
  @media screen and (min-width: 992px) {
    margin: 32px 80px 32px 80px;
  }
}
.sectionTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(6, 22, 69, 0.5);
}
.sectionText {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.infoImg {
  width: 13px;
  height: 13px;
  margin: 1px 1px 1px 6px;
  opacity: 0.5;
}