.jobTitle {
  margin-top: 20px;
  width: 100%;
  padding: 0 10px;

  input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    border: none;
    padding: 10px;
    height: auto;
  }

  @media screen and (min-width: 426px) {
    input {
      font-weight: 600;
      font-size: 32px;
    }
  }
}

.titleHelperText {
  padding: 10px 0 0 20px;
  font-size: 14px !important;
}



