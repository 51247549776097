/*.bodyWrapper {
  display: flex;
  flex-direction: row;
  min-height: 88vh;
}

.asideMenu {
  flex: 0 0 250px;
  background-color: blue;
}

.contentSection {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: yellow;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.content {
  overflow: auto;
}
*/

.topNav {
  background-color: var(--bs-theme-heading) !important;
}

.logo {
  width: 150px;
}

.customToolbar {
  min-height: 94px !important;
}

.mainBox {
  background-color: var(--bs-bg-dash-default);
}
