.section {
  display: flex;
  flex-direction: column;
  background-color: var(--bs-bg-dash-default);
  padding: 0 32px 20px 32px;
  min-height: calc(100vh - 184px - 92px);
}
.wrapper {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .wrapper {
    text-align: start;
  }
}

@media screen and (min-width: 576px) {
  .section {
    padding: 0 63px 20px 63px;
  }
  .wrapper {
    padding: 48px;
  }
}
