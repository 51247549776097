.wrapper {
  flex-grow: 1;
}
.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(6, 22, 69, 0.5);
}
.fileContainer {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.file {
  display: flex;
  align-items: center;
}
.fileName {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  margin-left: 8px;
}
.fileImg {
  margin: 0 2px;
}
.editIcon {
  width: 16px;
  height: 16px;
}
.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.infoImg {
  width: 13px;
  height: 13px;
  margin: 1px 1px 1px 6px;
  opacity: 0.5;
}
