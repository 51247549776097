.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 32px; /* Gap between items */
  margin-top: 24px;
  margin-bottom: 24px;
  @media screen and (min-width: 483px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(2, 1fr); /* Two rows */
  }
}
