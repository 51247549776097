.container {
  width: 100%;
  padding: 56px 32px 56px 32px;
  background-color: var(--bs-bg-dash-default);
  display: flex;
  justify-content: space-between;
  position: relative;
}
.stepper {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .container {
    padding: 56px 0;
  }
}
