.dividerWrapper {
  margin: 15px 0;
}

.divider {
  opacity: 0.20;
}

.dividerText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #061645;
  padding-top: 2px;
}

.dividerTextWrapper {
  margin-top: 2px;
}
