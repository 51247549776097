.rightHeadWrapper {
  margin: 8% 10% 0 10%;

  .logo {
    width: 150px;
    height: auto;
    
  }
}

@media(max-width: 768px) {
  .rightHeadWrapper .logo {
    display: block !important;
  }
}

@media(max-width: 767px) {
  .rightHeadWrapper{
    div {
      width: 100%;
      display: flex;
      align-items: left;
      justify-content: left;
    }

    .logo {
      margin-bottom: 25px;
    }

    .signupText {
      text-align: left;
    }
  }
}

.signupText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  color: rgba(6, 22, 69, 0.6);
}
