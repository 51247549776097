.jdWrapper {
  > div:not(:last-child) {
    padding-right: 15px;
    border-right: 1px solid var(--bs-theme-card-border);
  }
}
@media only screen and (max-width: 992px) {
  .jdWrapper {
    > div:not(:last-child) {
      border-right: 0;
      padding-right: 0;
    }
  }
}
@media only screen and (min-width: 992px) {
  .btDivider {
    display: none;
  }
}

.docLink {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}