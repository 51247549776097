.container {
  height: 92px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bs-theme-heading);
  padding: 16px 32px;
}
.logo {
  width: 150px;
  height: auto;
}
.userMenuWrapper {
  display: flex;
  align-items: center;
}
.avatar {
  width: 32px;
  height: 32px;
  background-color: var(--bs-white);
}
.menu {
  color: white;
}
.menuBig {
  display: none;
  align-items: center;
}

.dropdown {
  :hover {
    color: grey;
    border-color: var(--bs-theme-heading);
  }
  :active {
    color: grey !important;
    border-color: var(--bs-theme-heading) !important;
  }
  :focus-visible {
    color: grey;
  }
}
.canvas {
  height: "10px" !important;
}
@media screen and (min-width: 576px) {
  .container {
    padding: 16px 64px;
  }
}
@media screen and (min-width: 432px) {
  .menuSmall {
    display: none;
  }
  .menuBig {
    display: flex;
  }
}

.offCanvasMenuLink {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  color: var(--bs-primary-text);
  &:hover {
    color: var(--bs-primary);
  }
}

.ddMenuLink {
  width: 100%;
  display: inline-block;
  padding: 6px 0;
  color: var(--bs-primary-text);
  font-weight: 400;
}

.ddItem {
  &:active {
    background-color: var(--bs-white);
  }
}
