.wrapperSection {
  box-sizing: border-box;
  background-color: var(--bs-white);
  border-radius: 16px;
  min-height: 200px;
  width: 100%;
  padding: 0 15px 0 15px;
}

@media screen and (min-width: 769px) {
  .wrapperSection {
    padding: 0 30px 0 30px;
    min-height: 250px;
  }
}
