.CircularProgressWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.progressWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressCounter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.progressNameWrapper {
  text-align: right;
  display: flex;
  //   max-height: 92px;
  flex-direction: column;
  justify-content: space-around;
}
.progressName {
  font-size: 20px;

  font-weight: 500;

  @media screen and (min-width: 425px) {
    font-size: 26px;
    height: 42px;
  }
}
.nextProgressName {
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  margin-top: 10px;
}
