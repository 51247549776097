.wrapper {
  margin-top: 16px;
}
.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.planItem {
  background-color: var(--bs-bg-dash-default);
  border-radius: 16px;
  padding: 32px;
  width: 32%;
  margin-bottom: 25px;

  &.active {
    background-color: var(--bs-primary);

    .price, .pricePeriod, .planTitle, .description, .descriptionListItem {
      color: var(--bs-white);
    }
    .pricePeriod, .planTitle, .description, .descriptionListItem {
      opacity: 1;
    }
    .btn:disabled {
      background-color: var(--bs-white);
      color: var(--bs-primary);
      border-color: var(--bs-white);
      opacity: 1;
    }
  }
}
@media (max-width: 768px) {
  .planItem {
    width: 100%;
  }
}

.price {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: var(--bs-primary-text);
}

.pricePeriod {
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  opacity: 0.5;
}

.planTitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  margin-top: 16px;
  margin-bottom: 0;
}

.descriptionListItem {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  opacity: 0.5;
  margin-left: 10px;
}

.img {
  width: 20px;
  height: 20px;
}

.preferences {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.description {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  opacity: 0.5;
  margin-top: 21px;
}

.btn {
  margin-top: 40px;
  width: 100%;
  background-color: #fff;
  padding: 10px 32px;
}