.autoComplete {
  flex-grow: 1;

  input {
    border: 0;
  }
}

.delBtn {
  border: 0;
  background: transparent;
  margin: 0 0 0 20px;
  padding: 10px 0 0 0;

  &:active {
    transform: none;
    box-shadow: none;
  }
}