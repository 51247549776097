.file {
  display: flex;
  align-items: center;
  border-bottom: 1mm ridge var(--bs-bg-dash-default);
  padding-top: 16px;
  padding-bottom: 9px;

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
}
.fileImg {
  display: none;
  @media screen and (min-width: 650px) {
    display: block;
    padding-left: 11px;
    width: 42px;
    height: auto;
    color: #E2574C;
  }
}
.text {
  font-weight: 400;
  font-size: 16px;
  color: var(--bs-theme-heading);
  opacity: 0.5;
  padding-left: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: underline !important;
  }
}
.textFileSize {
  padding-left: 16px;
}
.iconBigScreen {
  /*display: none;*/
  margin-left: auto;
}
.icon {
  color: var(--bs-theme-heading);
  opacity: 0.5;
  margin-right: 16px;
}
@media screen and (min-width: 576px) {
  /*.iconBigScreen {
    display: block;
    margin-left: auto;
  }*/
  .text {
    font-size: 18px;
  }
}
