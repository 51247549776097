.mdlBtn {
  font-size: 14px;
  padding: 12px 20px;
}

.colorWhite {
  color: var(--bs-white);
}

.header {
  border-bottom: none;
}

.mdlBody {
  padding-left: 20px;
  padding-right: 20px;
}

.footer {
  border-top: none;
  margin-top: 10px;
}