.mainWrapper {
  height: 100vh;
}

.sectionLeft {
  min-height: 100%;
}

@media (max-width: 768px) {
  .sectionLeft {
    display: none;
  }
}

.logo {
  width: 150px;
  height: auto;
}
.logoWrapper {
  margin: 8% 0 0 8%;
}
