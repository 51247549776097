.dashBodyWrapper {
  background-color: var(--bs-bg-dash-default);
  min-height: calc(100vh - 92px);
  padding: 24px;
}

@media screen and (min-width: 576px) {
  .dashBodyWrapper {
    padding: 48px;
  }
}
