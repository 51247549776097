.container {
  background-color: var(--bs-bg-dash-default);
  border-radius: 8px;
  padding: 16px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.stepContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  height: 37px;
  background: #ffffff;
  border-radius: 0px 50px 50px 0px;
  margin-right: 10px;
}
.stepNumber {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--bs-primary-text);
}
.penImg {
  width: 18px;
  height: 18px;
  margin: 3px;
  color: var(--bs-primary-text);
  opacity: 0.5;
}
.stepName {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--bs-primary-text);
  opacity: 0.5;
  margin-top: 16px;
}
.text {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-top: 8px;
  margin-bottom: 12px;
}
.subText {
  font-weight: 300;
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 12px;
}
.fileContainer {
  display: flex;
  margin-top: 8px;
  align-items: center;
}
.fileName {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  opacity: 0.5;
  margin-left: 8px;
  word-break: break-all;
}
.img {
  margin: 0 2px;
  width: 35px;
  height: auto;
  color: #E2574C;
}
.infoImg {
  width: 20px;
  height: 20px;
  margin: 1px 1px 1px 6px;
  opacity: 0.5;
}
.list {
  color: var(--bs-primary-text);
  opacity: 0.5;
}
.title {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 12px;
}
.subTitle {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
}
.infoText {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;

  &.hglt {
    opacity: 1;
  }
}