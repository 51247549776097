.decorativeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fbfbfb;
  border: 1px dashed rgba(6, 22, 69, 0.3);
  border-radius: 8px;
  padding: 20px 32px;

  &.active {
    border: 2px dashed var(--bs-primary);
  }
}
.btnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  .decorativeContainer {
    padding: 40px 32px;
  }
  .btnWrapper {
    flex-direction: row;
    gap: 16px;
  }
}

.p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--bs-primary-text);
  opacity: 0.5;
}

.sizeAnotation {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--bs-primary-text);
  opacity: 0.5;
  margin-top: 24px;
}

.fileRejectContainer {
  padding: 10px;
  font-size: 14px;
  line-height: 25px;

  p {
    color: var(--bs-primary-text);
    font-weight: 600;
  }

  span {
    color: var(--bs-red);
    font-weight: normal;
  }
}

.instructionNotes {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}