.header {
  .logo {
    width: 150px;
    height: auto;
  }

  .menuIconContainer {
    display: none;

    button {
      border: 0;
      background: transparent;

      &:active {
        transform: none;
        box-shadow: none;
      }
    }
  }

  .menuIcon {
    width: 65px;
    height: auto;
    color: var(--bs-theme-heading);
  }

  @media screen and (max-width: 768px) {
    .btnContainer {
      display: none;
    }

    .menuIconContainer {
      display: block;
    }
  }
  
}
.mainMenuHead {
  padding: 30px;
  justify-content: end;
}
.mainMenuItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    padding: 10px;
    font-size: 25px;
    font-weight: 500;

    a {
      color: var(--bs-theme-heading);
    }
  }
}

.footer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 390px;
  background-color: #202430;
  color: var(--bs-white);
  text-align: center;
  font-family: 'Poppins';
  padding: 50px 20px;

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 40px;
  }
}
.footerBottom {
  width: 100%;
  background-color: var(--bs-white);
}
.ftLinksContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
  min-height: 100px;
  padding: 0 25px;
}
@media screen and (max-width: 426px) {
  .ftLinksContainer {
    flex-direction: column;
    justify-content: start;
    padding: 30px 25px;
  }
}
.ftLogoContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.ftLogo {
  width: 95px;
  height: auto;
}
.ftLink {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 400;
  &:hover {
    opacity: 0.5;
  }
}
