.appStatContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--bs-theme-card-border);
}
@media only screen and (max-width: 992px) {
  .appStatContainer {
    border-right: 0;
  }
}
