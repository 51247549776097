.thmBtn {
  font-family: 'poppins';
  font-weight: 500;
  font-size: 17px;
  padding: 14px 32px;

  &:hover, &:active {
    color: var(--bs-white) !important;
  }

  &.clrWhite {
    color: var(--bs-white) !important;
  }

  &.tbSmall {
    font-size: 14px;
    padding: 10px 15px;
  }
}

.btnIcon {
  margin-left: 8px;

  svg {
    stroke-width: 1;
  }
}
