.wrapper {
  text-align: center;
}
.title {
  font-size: 28px;
  line-height: 42px;
  margin-top: 30px;
  margin-bottom: 0;
}
.avatarWrapper {
  position: relative;
  display: inline-block;
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
.avatarBtnWrapper {
  position: absolute;
  bottom: -14px;
  right: 0;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border: solid;
  border-color: rgb(255, 255, 255);
  background-color: var(--bs-primary);
}
.avatarBtn {
  fill: white;
  width: 12px;
  height: auto;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 16px 24px; /* Gap between items */
  margin-top: 24px;
  padding: 0 20px;
  text-align: start;

  @media screen and (min-width: 483px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(3, 1fr); /* Two rows */
  }
  @media screen and (min-width: 992px) {
    padding: 0 80px;
  }
}
.documentContainer {
  border: 1px solid rgba(6, 22, 69, 0.1);
  border-radius: 8px;
  padding: 24px;
  margin: 32px 20px 0 20px;
  text-align: start;
  @media screen and (min-width: 992px) {
    margin: 32px 80px 0 80px;
  }
}
.btnWrapper {
  justify-content: center;
  display: flex;
}
.btn {
  color: rgb(255, 255, 255);
  flex-grow: 1;
  max-width: 400px;
  margin-top: 40px;
}
