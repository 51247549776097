.themeBox {
  box-sizing: border-box;
  background-color: var(--bs-white);
  border-radius: 14px;
  min-height: 100px;
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (min-width: 769px) {
  .themeBox {
    min-height: 150px;
  }
}
