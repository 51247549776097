.parentWrapper {
  display: flex;
  justify-content: space-between;

  input[type=text] {
    height: auto;
    padding: 10px 20px;
    border: 0;
    font-size: 18px;
    font-weight: 400;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 0 0 20px;
    padding: 10px 0 0 0;

    &:active {
      transform: none;
      box-shadow: none;
    }
  }
}


