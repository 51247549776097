.themeTable {
  &> :not(caption) > * > * {
    padding: 1rem 0.5rem;
  }

  .thead {
    background: rgba(6, 22, 69, 0.02);
    border-bottom: 1px solid rgba(6, 22, 69, 0.1);
  }

  .th {
    font-weight: 400;
    font-size: 14px;
    color: var(--bs-theme-gray-text);

    .sortable {
      cursor: pointer;
      font-weight: 500;
      color: var(--bs-primary-text);
      opacity: 0.6;
    }
  }

  .tr {
    border-bottom: 1px solid rgba(6, 22, 69, 0.1);
    color: var(--bs-theme-gray-text);
    font-weight: 400;
    font-size: 14px;
  }

  .standOutText {
    font-weight: 400;
    font-size: 16px;
    color: var(--bs-primary-text)
  }

  .tblBtn {
    font-size: 14px;
    white-space: nowrap;
    &:hover {
      color: var(--bs-white);
    }
  }

  .tblDelete {
    color: var(--bs-red);
    text-decoration: none;
    font-size: 14px;
    padding: 0;
    font-weight: 500;

    &:active, &:hover {
      color: var(--bs-theme-heading);
      transform: none;
      box-shadow: none;
    }
  }
}