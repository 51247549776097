.formWrapper {
  margin: 5% 10% 20% 10%;
}

.subLabels {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--bs-theme-heading);
}

.forgotPwd {
  text-align: right;
}
@media(max-width: 397px) {
  .rememberMe {
    width: 100%;
  }
  .forgotPwd {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }
}

.signupLinkedinBtn {
  padding: 14px 32px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--bs-theme-heading);
  border:1px solid #FFFFFF;

  &:hover {
    border:1px solid var(--bs-theme-heading);
    background: #FFFFFF;
  }

  &:active {
    border:1px solid var(--bs-theme-heading) !important;
    background: #FFFFFF !important;
  }

  img {
    margin: 0 15px 3px 0;
  }
}

