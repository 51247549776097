$theme-colors: (
  "primary": #019cde,
  "light-primary": #7BCFF4,
  "theme-heading": #061645,
  "bg-dash-default": #f7f8fe,
  "primary-text": #061645,
  "light-primary-text": rgba(76, 78, 100, 0.87),
  "theme-gray-text": rgba(6, 22, 69, 0.5),
  "theme-card-text": #6F6C90,
  "theme-card-border": rgba(6, 22, 69, 0.10)
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

/* import font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");

body,
html {
  font-family: "Inter", sans-serif;
  font-family: "Poppins", sans-serif;
}

// body {
//   height: 100vh;
//   width: 100%;
// }

/*input components*/
form label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(6, 22, 69, 0.9);
}

input[type="text"],
input[type="email"],
input[type="password"],
select {
  padding: 20px;
  height: 57px;
  border: 1px solid rgba(6, 22, 69, 0.1);
  border-radius: 8px;

  &.inpVariant1 {
    padding: 5px 10px;
    border-radius: 4px;
    height: 40px;
    border: 1px solid rgba(6, 22, 69, 0.1);
  }
}

button:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

a {
  color: var(--bs-primary);
  text-decoration: none;
  font-weight: 500;

  &:hover,
  &:active {
    color: var(--bs-theme-heading);
  }
}
p {
  margin: 0;
}
h2 {
  margin: 0;
}
/*
This will break mui components. need to fix
button {
  height: 55px;
}*/
.themeBtnFilled {
  border-radius: 8px;
  padding: 14px 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffff;

  &:hover {
    color: #ffff;
  }

  &:active {
    color: #ffff !important;
  }
}

.flUploadCtrBtn {
  width: 200px;

  &.btn {
    padding: 14px 32px;
  }

  &.btn-primary {
    color: var(--bs-white);

    &:hover, &:active {
      color: var(--bs-white);
    }
  }

  &.btn-outline-primary {
    &:hover, &:active {
      color: var(--bs-white);
    }
  }
}
@media screen and (min-width: 576px) {
  .flUploadCtrBtn {
    width: 307px;
  }
}
.flCtrBtnSepTxt {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--bs-primary-text);
  opacity: 0.5;
}

.themeInpGrpText {
  background-color: transparent;
  border-right: none;
}

.brd-l-0 {
  border-left: 0 !important;
}

.themeAddMoreBtn {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 32px;
  border-radius: 50px;
  border: 1px solid var(--bs-primary);
  background: var(--bs-primary);
  color: var(--bs-white);
  align-self: flex-start;
}
/*input components ends*/

.modal-backdrop {
  z-index: 1300;
}
.modal {
  z-index: 1301;
}

.cstStepConnector {
  .MuiStepConnector-line {
    border-color: var(--bs-primary);
    border-left-width: 2px;
  }
}

.dashHelperText {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
}

.dashTextBold {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
}

.dashSectionHead {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 22px;
  font-weight: 500;
}

.dashSectionSubHead {
  color: var(--bs-primary-text);
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
}

.themeH1 {
  color: var(--bs-theme-heading);
  font-family: 'Poppins';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
}
