.header {
  .logo {
    width: 150px;
    height: auto;
  }

  .menuIconContainer {
    display: none;

    button {
      border: 0;
      background: transparent;

      &:active {
        transform: none;
        box-shadow: none;
      }
    }
  }

  .menuIcon {
    width: 65px;
    height: auto;
    color: var(--bs-theme-heading);
  }

  @media screen and (max-width: 768px) {
    .btnContainer {
      display: none;
    }

    .menuIconContainer {
      display: block;
    }
  }
  
}
.mainMenuHead {
  padding: 30px;
  justify-content: end;
}
.mainMenuItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    padding: 10px;
    font-size: 25px;
    font-weight: 500;

    a {
      color: var(--bs-theme-heading);
    }
  }
}

.heroBanner {
  display: flex;
  padding-bottom: 40px;

  .left {
    flex-grow: 1;
    flex-basis: 0;
    background-color: rgba(0, 148, 218, 0.10);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 5%
  }

  .right {
    width: 510px;
    height: 597px;
    border-radius: 15px;
    margin-left: -5%;
    margin-top: 5%;
  }

  .rightSpacing {
    width: 100px;
  }

  @media screen and (min-width: 1780px) {
    .right {
      margin-top: 3%;
    }
  }

  @media screen and (max-width: 1239px) {
    .rightSpacing {
      display: none;
    }
  }

  @media screen and (max-width: 945px) {
    flex-direction: column;

    .left {
      padding-top: 10%;
      padding-bottom: 40%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .right {
      width: 80%;
      margin: -35% auto 0 auto;
    }
  }

  @media screen and (max-width: 480px) {
    .right {
      background-position: center;
      height: 375px;
    }
  }

  .leftContentWrapper {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    margin-left: 10%;
    margin-right: auto;
    justify-content: center;

    .btnWrapper {
      display: flex;
    }

    @media screen and (max-width: 480px) {
      .btnWrapper {
        flex-direction: column;
        flex: 1;

        button {
          margin: 10px 0 !important;
          font-size: 17px;
        }
      }
    }
  }

  @media screen and (max-width: 945px) {
    .leftContentWrapper {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .bannerHeading {
    color: var(--bs-theme-heading);
    font-family: 'Poppins';
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px;
    padding: 0 0 20px 0;
  }

  .bannerText {
    color: var(--bs-theme-heading);
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding: 0 0 40px 0;
  }

  .lmBtn {
    background: var(--bs-white);

    &:hover {
      color: var(--bs-btn-hover-color);
      background-color: var(--bs-btn-hover-bg);
    }
  }

  @media screen and (max-width: 480px) {
    .bannerHeading {
      font-size: 42px;
      font-weight: 700;
      line-height: 60px;
    }

    .bannerText {
      font-size: 17px;
      line-height: 27px;
    }
  }
}

.learnMore {
  width: 88%;
  margin: 40px auto;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    color: var(--bs-theme-heading);
    font-family: 'Poppins';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px;
    text-align: center;
    margin-bottom: 40px;
  }

  .cardContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;

    .lnCard {
      display: flex;
      padding: 35px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-radius: 10px;
      border: 1px solid #E5E5E5;
      background: #FFF;
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

      div {
        width: 61.667px;
        height: 60px;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      h3 {
        color: var(--bs-theme-heading);
        font-family: 'Poppins';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }

      p {
        color: var(--bs-theme-card-text);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }

  @media screen and (max-width: 890px) {
    .cardContainer{
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 767px) {
    .cardContainer{
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 767px) {
  .learnMore{
    h2 {
      font-size: 35px;
      line-height: 60px;
    }
  }
}

.getStarted {
  display: flex;
  align-items: end;

  .lftSection {
    flex-grow: 1;
    flex-basis: 0;
    background-color: rgba(0, 148, 218, 0.10);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 5%;

    .gsWrapper {
      width: 70%;
      margin: 50px auto 50px 9%;
      display: flex;
      flex-direction: column;
      gap: 1.5em;

      h2 {
        color: var(--bs-theme-heading);
        font-family: 'Poppins';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 76px; 
      }

      .stepLabel {
        color: var(--bs-theme-heading);
        font-family: 'Poppins';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        padding: 5px 10px 0;
      }

      .stepDesc {
        color: var(--bs-theme-heading);
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        padding: 0 10px 5px;
      }

      svg {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }

  .rtSection {
    width: 600px;
    height: 673px;
    margin-left: -8%;
    //margin-top: 5%;
  }

  @media screen and (max-width: 1200px) {
    .rtSection {
      width: 40%;
    }
  }

  @media screen and (max-width: 945px) {
    .rtSection {
      display: none;
    }

    .lftSection {
      display: flex;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 40px 0;

      .gsWrapper {
        width: 100%;
        margin: 50px 9%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .lftSection {

      .gsWrapper {

        h2 {
          font-size: 35px;
          line-height: 60px;
        }

        .stepLabel {
          font-size: 18px;
        }
  
        .stepDesc {
          font-size: 16px;
        }
  
        svg {
          width: 35px !important;
          height: 35px !important;
        }
      }
    }
  }
}

.subsPlans {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  h2 {
    color: var(--bs-theme-heading);
    text-align: center;
    font-family: 'Poppins';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .planContainer {
    display: flex;
    justify-content: space-between;
    width: 88%;
    margin: 0 auto;
    padding-bottom: 40px;
  }

  .pCard {
    width: 32%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 30px;
    border-radius: 16px;
    border: 1px solid #E5E5E5;
    background: var(--bs-bg-dash-default);
    color: var(--bs-theme-heading);

    h3 {
      font-family: 'Poppins';
      font-size: 36px;
      font-style: normal;
      font-weight: 700;

      span {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.5;
      }
    }

    h4 {
      font-family: 'Poppins';
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        display: flex;
      }

      p {
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 7px;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    button {
      border-radius: 8px;
      border: 1px solid var(--bs-primary);
      color: var(--bs-primary);
      background: transparent;
      padding: 14px 32px;
      width: 100%;
      margin-top: 20px;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 500;
    }

    &:hover {
      color: var(--bs-white);
      background: var(--bs-primary);
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

      button {
        background: var(--bs-white);
        border: 1px solid var(--bs-white);
      }

      h3 {
        span {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .planContainer {
      flex-direction: column;
      justify-content: center;
      gap: 25px;
    }

    .pCard {
      width: 100%;
    }
  }
}

.faq {
  display: flex;
  padding: 65px 0;
  background-color: rgba(0, 148, 218, 0.1);
  flex-direction: column;

  h2 {
    color: var(--bs-theme-heading);
    text-align: center;
    font-family: 'Poppins';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px;
    margin-bottom: 30px;
  }
}
.faqContainer {
  display: flex;
  width: 88%;
  margin: 0 auto;
  justify-content: space-between;
}
.faqInner {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}
.faqItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 16px;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  padding: 30px 20px;
  background-color: var(--bs-white);

  .accHead {
    display: flex;
    justify-content: space-between;
    color: var(--bs-theme-heading);
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .accBtn {
    width: 41.5px;
    height: 41.5px;
    padding: auto auto;
    background-color: rgba(0, 148, 218, 0.1);
    color: var(--bs-primary);
    border-radius: 8px;
    border: 0;

    &.bActive {
      color: var(--bs-white);
      background-color: var(--bs-primary);
    }
  }

  .accBody {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.accActive {
      height: 100%;
      max-height: 250px;
      transition: max-height 0.5s ease-in-out;
    }
  }
}
@media screen and (max-width: 767px) {
  .faqContainer {
    flex-direction: column;
  }
  .faqInner {
    width: 100%;
  }
}

.footer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 390px;
  background-color: #202430;
  color: var(--bs-white);
  text-align: center;
  font-family: 'Poppins';
  padding: 50px 20px;

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 40px;
  }
}
.footerBottom {
  display: flex;
  min-height: 100px;
  background-color: var(--bs-white);
}
