.wrapper {
  text-align: center;
}
.title {
  font-size: 28px;
  line-height: 42px;
  margin-top: 30px;
  margin-bottom: 0;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 16px 24px; /* Gap between items */
  margin-top: 24px;
  padding: 0 20px;
  text-align: start;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr); /* Two columns */
    grid-template-rows: repeat(1, 1fr); /* Two rows */
  }
  @media screen and (min-width: 992px) {
    padding: 0 80px;
  }
}
.btnWrapper {
  justify-content: center;
  display: flex;
}

